import React, { Component } from 'react'; 

export default class MyoFunctionalTreatment extends Component {
    render() {
        return (
            <div>
                <div className="box-container">
                    <div className="main-title">
                        <div className="container">
                            <h2 className="main-title__primary">MyoFunctional Treatment</h2>
                        </div>
                    </div>
                </div>
                <section className="space_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                                <p><strong>Myofunctional therapy </strong>is a program used to correct the improper function of the tongue and facial muscles.  It involves strengthening of the tongue and orofacial muscles by teaching children how to engage the muscles to the appropriate position.</p>
                                <br /><p>Children with predominant mouth breathing (or insufficient habitual nasal breathing) often develop improper habits and patterns of orofacial function that may impact various conditions including:</p>
                                <ul>
                                    <li>Facial skeletal growth (maxillofacial development)</li>
                                    <li>Sleep-disordered breathing (including snoring and sleep apnea)</li>
                                    <li>Nasal obstruction</li>
                                    <li>Oral hygiene and dental problems</li>
                                    <li>Teeth grinding (bruxism)</li>
                                    <li>Temporomandibular joint dysfunction</li>
                                    <li>Neck and shoulder tension</li>
                                    <li>Speech problems</li>
                                    <li>Facial esthetics</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="MyoFunctional">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                                <p>Proper diagnosis allows for targeted and effective physical therapy exercises for oral posture retraining to promote better health with goals to improve breathing, reduce pain, and enhance quality of life.</p>
                                <h2 className="text-center">What are Myofunctional Appliances?</h2>
                                <p>A removable or a fixed appliance which changes the position of mandible so as to transmit forces generated by the stretching of the muscles through the components of the appliance to the dentition and the under lying skeletal structures.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="myofunctional_appliances">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                                <h2 className="text-center">When are myofunctional appliances recommended?</h2>
                                <ul>
                                    <li>In cases of mild skeletal discrepancy</li>
                                    <li>Forwardly angulated upper incisor teeth</li>
                                    <li>Used commonly to improve the upper – lower jaw relationship before starting the fixed appliance treatment.</li>
                                    <li>Extremely useful in class II malocclusion cases</li>
                                    <li>They reduce the amount of comprehensive fixed therapy required</li>
                                    <li>They reduce the need of orthognathic surgery</li>
                                    <li>Early intervention indicated when one wishes to utilize their growth enhancing effect</li>
                                    <li>Extremely effective in reducing the relative prominence of the upper incisors which are particularly susceptible to dentoalveolar trauma.</li>    
                                </ul>
                                <h3>Changes that can be achieved through myofunctional treatment:</h3>
                                <ol>
                                    <li>Capable of accelerating the growth of the lower jaw if the growth is lagging behind</li>
                                    <li>Can be designed to have a restrictive influence on the growth of jaws</li>
                                    <li>Can change the direction of growth in jaws</li>
                                    <li>Inhibition of downward and forward eruption of maxillary teeth</li>
                                    <li>Correction of angulation of upper incisors</li>
                                    <li>Forward angulation of lower incisor</li>
                                    <li>Improve the tonicity of orofacial musculature</li>
                                    <li>Removal of the lip trap and improved lip competence</li>
                                    <li>Removal of adaptive tongue activity</li>
                                    <li>Lowering of the rest position of the mandible</li>
                                    <li>Removal of the soft tissue pressures from the cheeks and lips</li>
                                </ol>
                                <h5>Best time to take advantage of the myofunctional treatment</h5>
                                <p>Growing patient between <strong>10 years and pubertal growth</strong>. A patient may require orthodontic treatment or functional orthopedic treatment or a combination of both and to varying degree</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="common_myofunctional">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 text-center">
                                <h2>Common myofunctional appliances</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                                <img src="/assets/img/oral screen.jpg" alt="oral screen" />
                                <h3 className="text-center"><u>Oral screen</u></h3>
                                <p>Takes the form of a curved shield of acrylic placed in the labial vestibule. Indicated to intercept mouth breathing, thumb sucking, tongue thrusting , lip bititng and cheek biting. To perform muscle exercises to help in correction of hypotonic lip and cheek muscles</p>
                            </div>
                            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                                <img src="/assets/img/lip-bumper7.jpg" alt="lip-bumper7" />
                                <h3 className="text-center"><u>Lip bumper</u></h3>
                                <p>This appliance is used to shield the lips away from the teeth. Used in lip sucking patients, hyperactive mentalis activity to distalize molars</p>
                            </div>
                            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                                <img src="/assets/img/function regulator.jpg" alt="function regulator" />
                                <h3 className="text-center"><u>Function regulator</u></h3>
                                <p>It removes the muscle forces in the labial and buccal areas thereby providing an environment which enables skeletal growth</p>
                            </div>
                            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                                <img src="/assets/img/activator appliance.jpg" alt="activator appliance" />
                                <h3 className="text-center"><u>Activator</u></h3>
                                <p>It is used in actively growing individuals with favorable growth patterns as a preliminary treatment before major fixed appliance therapy to improve skeletal jaw relations, for post treatment retention and in children with lack of vertical development in lower facial height</p>
                                <p><strong>Advantages:</strong></p>
                                <ol>
                                    <li>Uses existing growth of the jaws</li>
                                    <li>Minimal oral hygiene problems</li>
                                    <li>Intervals between appointments is long</li>
                                    <li>Appointments are short with minimal adjustment required hence more economical</li>
                                </ol>
                            </div>
                            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                                <img src="/assets/img/twin-block.jpg" alt="twin-block" />
                                <h3><u>Twin block appliance</u></h3>
                                <p>Twin block appliance is a removable, orthodontic functional appliance that is used to help correct jaw alignment, particularly an underdeveloped lower jaw.
                                <br />The removable twin block is a tissue born functional appliance that is worn full time. It helps in the advancement of the mandible. It is a two piece appliance composed of an upper and lower bite block. Orthopedic traction can be added in cases of severe skeletal discrepancies.</p>
                                <p><strong>Advantage,</strong> there is significant change in the patient’s appearance within 2 to 3 months</p>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="remember">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 ">
                                <h2 className="text-center">When to start with functional appliance</h2>
                                <p>The best time to start functional appliance therapy is the 8 to 12 years (late mixed dentition period).<br />
                                    We prefer to take advantage of the pubertal growth spurt<br />Girls and boys along with early maturers should be assessed individually
                                </p>
                                <p>Global demand for orthodontics without braces continues to grow. It’s an option that many parents and patients would prefer.<br />
                                    Myofunctional orthodontics offers a viable alternative to traditional orthodontic methods<br />
                                    A functional appliance is an appliance that produces all or part of its effect by altering the position of the mandible/ maxilla<br />
                                    These appliances use the muscle action of the patient to produce orthodontic or orthopedic forces to restore facial balance.<br />
                                </p>
                                <p>The question that must be addressed in diagnosis is “does?”</p>    
                            </div>
                        </div>
                    </div>        
                </section>
                <section className="next-appoint">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 text-center" style={{paddingBottom:20}}>
                                <h2 style={{fontSize:40}}>Ready to book your next appointment?</h2>
                            </div>
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 text-center" style={{paddingBottom:40}}>
                                <a className="book_app more inner-btn" href="/appointment/">Book online</a>&nbsp;<a className="num_app more inner-btn" href="tel:+91 6358834088"><i  className="fa fa-phone"></i>&nbsp;+91 6358834088</a>
                            </div>
                        </div>
                    </div>   
                </section>    
            </div>
        )
    }
}
